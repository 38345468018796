import { Box, Flex, SectionMessage } from '@plugsurfing/plugsurfing-design';
import { CdButton, CdField, CdFormSection, CdFormWrapper } from 'components/design-elements';
import { Loading } from 'components/general';
import * as Links from 'config/links';
import { Formik, FormikActions } from 'formik';
import { LocalesKey } from 'i18n';
import { CognitoErrorCode, SignInResultType } from 'models/cognito';
import { QRCodeCanvas } from 'qrcode.react';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, RouteComponentProps, StaticContext } from 'react-router';
import { AuthStatus, selectAuthState, selectAuthUser } from 'redux/slices/auth';
import AuthService from 'services/AuthService';
import { p3Theme } from 'styles/theme';
import { findMessage } from 'utils/formatters';
import { notEmpty, pickErrors } from 'utils/forms';
import { CheckboxField, InputField } from 'utils/forms/renders';
import { useCdToast } from 'utils/toast';
import LogoHeading from 'views/auth/LogoHeading';
import styles from './SignInView.module.scss';

interface FormDataMFA {
  token: string;
  trustDevice: boolean;
}

const initialValueMFA: FormDataMFA = {
  token: '',
  trustDevice: false,
};

function validateMFACode(data: FormDataMFA) {
  return pickErrors({ token: notEmpty(data.token) });
}

function getLocalizedCognitoMFAError({ code }: Error & { code?: CognitoErrorCode }, t: (key: LocalesKey) => string) {
  switch (code) {
    case CognitoErrorCode.CodeMismatch:
    case CognitoErrorCode.EnableSoftwareTokenMFA:
      return t('errorCodeMismatchMFA');
    case CognitoErrorCode.NotAuthorized:
      return t('errorSessionExpiredMFA');
    default:
      return t('somethingWentWrong');
  }
}

export type MFAuthViewProps = RouteComponentProps<any, StaticContext, { redirectTo?: string }>;

const MFAuthView = memo((props: MFAuthViewProps) => {
  const { t } = useTranslation();
  const toast = useCdToast();
  const user = useSelector(selectAuthUser);
  const dispatch = useDispatch();
  const authState = useSelector(selectAuthState);
  const [qrCode, setQrCode] = useState('');
  const [isLoading, setLoading] = useState(false);
  const hasUserSetupMFA = user?.challengeName === SignInResultType.MFA;
  const shouldShowQRCode = user && !hasUserSetupMFA && !!qrCode;
  const { state: { redirectTo = Links.ROOT().path } = {} } = props.location;

  const generateQRCode = useCallback(async () => {
    try {
      setLoading(true);
      const qrCode = await AuthService.setupTOTPAuth(authState);
      setQrCode(qrCode);
    } catch (error) {
      toast.error(findMessage(error));
    } finally {
      setLoading(false);
    }
  }, [authState, toast]);

  useEffect(() => {
    if (user && !hasUserSetupMFA) {
      void generateQRCode();
    }
  }, [user, hasUserSetupMFA]);

  const handleSubmit = useCallback(
    async (values: FormDataMFA, { setSubmitting }: FormikActions<FormDataMFA>) => {
      try {
        await AuthService.confirmSignInTOTP(authState, dispatch, values.token, hasUserSetupMFA, values.trustDevice);
      } catch (error) {
        toast.error(getLocalizedCognitoMFAError(error as Error, t));
      } finally {
        setSubmitting(false);
      }
    },

    [authState, dispatch, toast, hasUserSetupMFA, t],
  );

  if (authState.state.status === AuthStatus.SignedIn) {
    return <Redirect to={redirectTo} />;
  }

  if (!user) {
    return <Redirect to={Links.SIGNIN().pathPart} />;
  }

  return (
    <div className={styles.container}>
      <Box
        width={{ base: '100vw', md: '500px' }}
        boxShadow={{ base: 'none', md: 'lg' }}
        borderRadius={{ base: 'none', md: 'm' }}
        borderWidth={p3Theme.borderWidths.s}
        borderStyle="solid"
        borderColor={{ base: 'transparent', md: 'border.primary' }}
        padding="s"
      >
        {isLoading && <Loading />}
        <Formik
          initialValues={initialValueMFA}
          validate={validateMFACode}
          onSubmit={handleSubmit}
          render={({ isSubmitting, handleSubmit }) => (
            <CdFormWrapper onSubmit={handleSubmit}>
              <Flex padding={{ base: 's', md: '2xl' }} direction="column" gap="m">
                <LogoHeading isSetupMFA={shouldShowQRCode}>
                  {shouldShowQRCode ? t('loginSetupMFAHeader') : t('loginMFAHeader')}
                </LogoHeading>
                {shouldShowQRCode && (
                  <>
                    <SectionMessage status="info" description={t('mfaSetupDescription')} />
                    <Box pb="m" alignSelf="center">
                      <QRCodeCanvas value={qrCode} size={256} />
                    </Box>
                  </>
                )}
                <CdFormSection pb="m" gap="xl">
                  <CdField
                    name="token"
                    type="number"
                    label={shouldShowQRCode ? t('mfaCode') : t('mfaCodeConfirm')}
                    isDisabled={isSubmitting}
                    component={InputField}
                    autoFocus
                  />
                  <CdField name="trustDevice" component={CheckboxField} label={t('trustDevice')} />
                </CdFormSection>
                <CdButton w="100%" primary type="submit" size="S" isDisabled={isSubmitting} isLoading={isSubmitting}>
                  {t('confirm')}
                </CdButton>
              </Flex>
            </CdFormWrapper>
          )}
        />
      </Box>
    </div>
  );
});

export default MFAuthView;
