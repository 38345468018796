import { Auth } from '@aws-amplify/auth';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CDM_URL } from 'config/constants';
import { CDMError } from 'services/CDMError';

export const REDUCER_PATH = 'rtkQuery';

const cdmFetchBaseQuery = fetchBaseQuery({
  baseUrl: CDM_URL,
  prepareHeaders: async headers => {
    const cognitoUser = await Auth.currentSession().catch(() => undefined);

    if (cognitoUser === undefined) {
      // User is not logged in
      location.reload();
      return Promise.reject();
    }

    headers.set('authorization', cognitoUser.getIdToken().getJwtToken());

    // setting up headers for connecting to cdm api locally
    const isLocal = CDM_URL.indexOf('localhost') > 0;
    if (isLocal) {
      const { sub, userId } = cognitoUser.getIdToken().payload;
      headers.set('cd-claims-cognito-username', sub);
      headers.set('cd-claims-user-id', userId);
    }

    return headers;
  },
  responseHandler: 'content-type', // To not attempt to parse CSV as JSON
});

export const cdmApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: async (args, api, extraOptions) => {
    const result = (await cdmFetchBaseQuery(args, api, extraOptions)) as QueryReturnValue<any, any, object>;

    if ('error' in result) {
      console.error(result.error);

      return {
        ...result,
        error: CDMError.fromErrorObject(result.error?.data as object, result.error?.status as number),
      };
    }

    return result;
  },
  endpoints: () => ({}),
  refetchOnFocus: true,
  refetchOnReconnect: true,
});

export type State = ReturnType<typeof cdmApi.reducer>;
