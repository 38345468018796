import { SystemUser } from '@plugsurfing/cdm-api-client';
import { LocalesKey, t } from 'i18n';
import Link from 'models/Link';
import { getNameV2 } from 'utils/forms/address';
import {
  AUTHRESULTREAD,
  CAMPAIGNREAD,
  CHARGEPOINTCONNECTIONPROFILEREAD,
  CHARGEPOINTCONNECTIONPROFILEUPDATE,
  CHARGEPOINTNOTIFICATIONPROFILECREATE,
  CONNECTORGROUPSGROUPSREAD,
  CUSTOMERINVOICEREAD,
  CUSTOMERKEYREAD,
  EMPCPOASSOCIATIONREAD,
  EVSEMAPPINGCREATE,
  EXTERNALROAMINGINTEGRATIONSCREATE,
  EXTERNALROAMINGINTEGRATIONSREAD,
  EXTERNALROAMINGINTEGRATIONSUPDATE,
  FLEETMEMBERINVITECREATE,
  FLEETMEMBERINVITEREAD,
  GENERALEXPORTREAD,
  INVOICERECONCILIATIONCREATE,
  MARKETPLACEAGREEMENTCREATE,
  MARKETPLACEAGREEMENTREAD,
  ORGANIZATIONBRANDINGREAD,
  ORGANIZATIONCREATE,
  ORGANIZATIONELWINACCOUNTCREATE,
  ORGANIZATIONREAD,
  ORGANIZATIONREGISTRATIONREAD,
  ORGANIZATIONTEMPLATEREAD,
  ORGANIZATIONUPDATE,
  PRODUCTCREATE,
  PRODUCTREAD,
  PRODUCTUPDATE,
  PUBLISHINGCHANNELSET,
  SUBSCRIPTIONCREATE,
  SUBSCRIPTIONREAD,
  TEMPLATEREAD,
} from 'utils/roles';
import { DynamicLinkProps, DynamicLinkPropsV2, ROOT, dynamicLinkParams } from './common';

export const ORGANIZATIONS = () =>
  Link.static({
    nameLocaleKey: 'organizations',
    pathPart: 'organizations',
    parent: ROOT(),
    allowedPrivileges: [ORGANIZATIONREAD],
  });

export const CHARGING_STATION_TEMPLATES = () =>
  Link.static({
    nameLocaleKey: 'chargingStationTemplates',
    pathPart: 'charging-station-templates',
    parent: ORGANIZATIONS(),
  });

export const CREATE_ORGANIZATION = () =>
  Link.static({
    nameLocaleKey: 'organizationCreate',
    pathPart: 'create-organization',
    parent: ORGANIZATIONS(),
  });

export const campaignLink = (parent: Link, { id, name }: DynamicLinkProps = dynamicLinkParams): Link =>
  new Link({
    name,
    pathPart: id,
    parent,
  });

export const campaignsLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'campaigns',
    pathPart: 'campaigns',
    parent,
    allowedPrivileges: [CAMPAIGNREAD],
  });

export const chargingStationTemplateLink = (parent: Link, { id, name } = dynamicLinkParams): Link =>
  new Link({
    name,
    pathPart: id,
    parent,
  });

export const chargingStationTemplatesLink = (parent: Link): Link =>
  new Link({
    name: CHARGING_STATION_TEMPLATES().name,
    pathPart: 'charging-station-templates',
    parent,
    allowedPrivileges: [ORGANIZATIONTEMPLATEREAD],
  });

export const cpoConfigurationLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'cpoConfiguration',
    pathPart: 'cpo-configuration',
    parent,
    allowedPrivileges: [
      CHARGEPOINTCONNECTIONPROFILEREAD,
      CHARGEPOINTCONNECTIONPROFILEUPDATE,
      PUBLISHINGCHANNELSET,
      EVSEMAPPINGCREATE,
    ],
  });

export const aoConfigurationLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'aoConfiguration',
    pathPart: 'ao-configuration',
    parent,
    allowedPrivileges: [EVSEMAPPINGCREATE],
  });

export const empConfigurationLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'empConfiguration',
    pathPart: 'emp-configuration',
    parent,
    allowedPrivileges: [ORGANIZATIONUPDATE, EMPCPOASSOCIATIONREAD],
  });

export const empInvoicingLink = (organization: DynamicLinkPropsV2): Link =>
  Link.static({
    nameLocaleKey: 'empInvoices',
    pathPart: 'emp-invoicing',
    parent: organizationLink(organization),
    allowedPrivileges: 'ORG_INVOICING_READ',
  });

export const empInvoicingVariableFeesLink = (organization: DynamicLinkPropsV2): Link =>
  Link.static({
    nameLocaleKey: 'variableFees',
    pathPart: 'variable-fees',
    parent: empInvoicingLink(organization),
    allowedPrivileges: 'ORG_INVOICING_READ',
  });

export const empInvoicingVariableFeeAddLink = (organization: DynamicLinkPropsV2): Link =>
  Link.static({
    nameLocaleKey: 'addUnitPrice',
    pathPart: 'add',
    parent: empInvoicingVariableFeesLink(organization),
    allowedPrivileges: 'ORG_INVOICING_WRITE',
  });

export const empInvoicingFixedFeeAddLink = (organization: DynamicLinkPropsV2): Link =>
  Link.static({
    nameLocaleKey: 'addMonthlyFees',
    pathPart: 'add',
    parent: empInvoicingFixedFeesLink(organization),
    allowedPrivileges: 'ORG_INVOICING_WRITE',
  });

export const empInvoicingFixedFeesLink = (organization: DynamicLinkPropsV2): Link =>
  Link.static({
    nameLocaleKey: 'monthlyFees',
    pathPart: 'monthly-fees',
    parent: empInvoicingLink(organization),
    allowedPrivileges: 'ORG_INVOICING_READ',
  });

export const empInvoicingOneTimeFeesLink = (organization: DynamicLinkPropsV2): Link =>
  Link.static({
    nameLocaleKey: 'oneTimeFees',
    pathPart: 'one-time-fees',
    parent: empInvoicingLink(organization),
    allowedPrivileges: 'ORG_INVOICING_READ',
  });

export const empInvoicingOneTimeFeeAddLink = (organization: DynamicLinkPropsV2): Link =>
  Link.static({
    nameLocaleKey: 'addOneTimeFee',
    pathPart: 'add',
    parent: empInvoicingOneTimeFeesLink(organization),
    allowedPrivileges: 'ORG_INVOICING_WRITE',
  });

export const empInvoicingChargingSessionsLink = (organization: DynamicLinkPropsV2): Link =>
  Link.static({
    nameLocaleKey: 'chargingSessionFees',
    pathPart: 'charging-sessions',
    parent: empInvoicingLink(organization),
    allowedPrivileges: 'ORG_INVOICING_READ',
  });

export const empInvoicingChargingSessionsAddLink = (organization: DynamicLinkPropsV2): Link =>
  Link.static({
    nameLocaleKey: 'addChargingSessionFees',
    pathPart: 'add',
    parent: empInvoicingChargingSessionsLink(organization),
    allowedPrivileges: 'ORG_INVOICING_WRITE',
  });

export const createCampaignLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'campaignCreate',
    pathPart: 'create-new-campaign',
    parent,
  });

export const createChargingStationTemplateLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'chargingStationTemplateCreate',
    pathPart: 'create-charging-station-template',
    parent,
  });

export const notificationProfilesCreationLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'notificationProfileAdd',
    pathPart: 'add-notification-profile',
    parent,
  });

export const notificationProfilesLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'notificationProfiles',
    pathPart: 'notification-profiles',
    parent,
    allowedPrivileges: [CHARGEPOINTNOTIFICATIONPROFILECREATE],
  });

export const organizationLink = ({ id, name }: DynamicLinkPropsV2 = { id: ':id', name: '' }): Link =>
  new Link({
    name: name || '',
    pathPart: id,
    parent: ORGANIZATIONS(),
  });

export const elwinAccountRegistrationLink = (organization: DynamicLinkPropsV2): Link =>
  Link.static({
    nameLocaleKey: 'elwinAccountRegistration',
    pathPart: 'elwin-registration',
    parent: organizationLink(organization),
    allowedPrivileges: [ORGANIZATIONELWINACCOUNTCREATE],
  });

export const supportingOrganizationsLink = (parent: Link = organizationLink()): Link =>
  Link.static({
    nameLocaleKey: 'organizationSupporting',
    pathPart: 'supported-organizations',
    parent,
  });

export const addSupportingOrganizationLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'organizationAddSupporting',
    pathPart: 'add-supported-organization',
    parent,
  });

export const systemUserLink = (parent: Link, user?: SystemUser, name?: string): Link =>
  new Link({
    name: user ? name || getNameV2(user) : name || '',
    pathPart: user ? user.id : ':id',
    parent,
  });

export const addBillingSettingsLink = (organization: DynamicLinkPropsV2): Link =>
  Link.static({
    nameLocaleKey: 'organizationAddBilling',
    pathPart: 'add-billing-settings',
    parent: organizationLink(organization),
  });

export const reportsLink = (organization: DynamicLinkPropsV2): Link =>
  Link.static({
    nameLocaleKey: 'reports',
    pathPart: 'reports',
    parent: organizationLink(organization),
    allowedPrivileges: [GENERALEXPORTREAD],
  });

export const fleetDrivers = (parent: Link) =>
  Link.static({
    nameLocaleKey: 'fleetDrivers',
    pathPart: 'fleet-drivers',
    parent,
    allowedPrivileges: [FLEETMEMBERINVITEREAD],
  });

export const inviteFleetDriver = (parent: Link) =>
  Link.static({
    nameLocaleKey: 'fleetDriversInvite',
    pathPart: 'invite',
    parent,
    allowedPrivileges: [FLEETMEMBERINVITECREATE],
  });

export const externalConnectorGroups = (organization: DynamicLinkPropsV2): Link =>
  Link.static({
    nameLocaleKey: 'externalConnectorGroups',
    pathPart: 'external-connector-groups',
    parent: organizationLink(organization),
    allowedPrivileges: [CONNECTORGROUPSGROUPSREAD],
  });

export const externalConnectorGroup = (organization: DynamicLinkPropsV2, { name, id } = dynamicLinkParams): Link =>
  new Link({
    name,
    pathPart: id,
    parent: externalConnectorGroups(organization),
    allowedPrivileges: [CONNECTORGROUPSGROUPSREAD],
  });

export const marketplaceAgreements = (organization: DynamicLinkPropsV2): Link =>
  Link.static({
    nameLocaleKey: 'agreements',
    pathPart: 'marketplace',
    parent: organizationLink(organization),
    allowedPrivileges: [MARKETPLACEAGREEMENTREAD],
    exact: true,
  });

export const addMarketplaceProvider = (organization: DynamicLinkPropsV2): Link =>
  Link.static({
    nameLocaleKey: 'addMarketplaceProvider',
    pathPart: 'add-provider',
    parent: marketplaceAgreements(organization),
    allowedPrivileges: [MARKETPLACEAGREEMENTCREATE],
  });

export const addMarketplaceReceiver = (organization: DynamicLinkPropsV2): Link =>
  Link.static({
    nameLocaleKey: 'addMarketplaceReceiver',
    pathPart: 'add-receiver',
    parent: marketplaceAgreements(organization),
    allowedPrivileges: [MARKETPLACEAGREEMENTCREATE],
  });

export const marketplaceIntegrations = (organization: DynamicLinkPropsV2): Link =>
  Link.static({
    nameLocaleKey: 'integrations',
    pathPart: 'integrations',
    parent: organizationLink(organization),
    allowedPrivileges: [EXTERNALROAMINGINTEGRATIONSREAD],
    exact: true,
  });

export const marketplaceIntegration = (organization: DynamicLinkPropsV2, { name, id } = dynamicLinkParams): Link =>
  new Link({
    name,
    pathPart: id,
    parent: marketplaceIntegrations(organization),
    allowedPrivileges: [EXTERNALROAMINGINTEGRATIONSREAD],
  });

export const createNewIntegration = (organization: DynamicLinkPropsV2): Link =>
  new Link({
    name: t('createNewIntegration'),
    pathPart: 'create-new-integration',
    parent: marketplaceIntegrations(organization),
    allowedPrivileges: [EXTERNALROAMINGINTEGRATIONSCREATE, EXTERNALROAMINGINTEGRATIONSUPDATE],
  });

export const addCPOToHubjectIntegrations = (organization: DynamicLinkPropsV2, { name, id } = dynamicLinkParams): Link =>
  new Link({
    name: t('addSubCPO'),
    pathPart: 'add-sub-cpo',
    parent: new Link({
      name,
      pathPart: id,
      parent: marketplaceIntegrations(organization),
      allowedPrivileges: [EXTERNALROAMINGINTEGRATIONSUPDATE],
    }),
  });

export const addCPOToIntegrations = (organization: DynamicLinkPropsV2, { name, id } = dynamicLinkParams): Link =>
  new Link({
    name: t('addCpo'),
    pathPart: 'add-cpo',
    parent: new Link({
      name,
      pathPart: id,
      parent: marketplaceIntegrations(organization),
      allowedPrivileges: [EXTERNALROAMINGINTEGRATIONSUPDATE],
    }),
  });

export const addOperatorMapping = (organization: DynamicLinkPropsV2, { name, id } = dynamicLinkParams): Link =>
  new Link({
    name: t('addOperatorMapping'),
    pathPart: 'add-operator-mapping',
    parent: new Link({
      name,
      pathPart: id,
      parent: marketplaceIntegrations(organization),
      allowedPrivileges: [EXTERNALROAMINGINTEGRATIONSCREATE],
    }),
  });

export const brandingLink = (organization: DynamicLinkPropsV2) =>
  Link.static({
    nameLocaleKey: 'branding',
    pathPart: 'branding',
    parent: organizationLink(organization),
    allowedPrivileges: [ORGANIZATIONBRANDINGREAD],
  });

export const invoicesLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'invoices',
    pathPart: 'invoices',
    parent,
    allowedPrivileges: [CUSTOMERINVOICEREAD],
  });

export const cpoInvoicesLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'cpoInvoiceUpload',
    pathPart: 'cpoInvoiceUpload',
    parent,
    allowedPrivileges: [INVOICERECONCILIATIONCREATE],
  });

export const creditsLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'credits',
    pathPart: 'credits',
    parent,
  });

export const oneTimeFeesLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'oneTimeFees',
    pathPart: 'one-time-fees',
    parent,
  });

export const templatesLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'templates',
    pathPart: 'templates',
    parent,
    allowedPrivileges: [TEMPLATEREAD],
  });

export const editTemplateLink = ({ id }: DynamicLinkPropsV2, parent: Link): Link =>
  new Link({
    name: `${id}`[0] === ':' ? '' : t(`templateFeatureName-${id}` as LocalesKey) || '',
    pathPart: id,
    parent,
  });

export const subscriptionTemplatesLink = (organization: DynamicLinkPropsV2): Link =>
  Link.static({
    nameLocaleKey: 'subscriptions',
    pathPart: 'subscription-templates',
    parent: organizationLink(organization),
    allowedPrivileges: [SUBSCRIPTIONREAD],
  });

export const subscriptionTemplateLink = (organization: DynamicLinkPropsV2, { name, id } = dynamicLinkParams): Link =>
  new Link({
    name,
    pathPart: id,
    parent: subscriptionTemplatesLink(organization),
    allowedPrivileges: [SUBSCRIPTIONREAD],
  });

export const createSubscriptionTemplateLink = (organization: DynamicLinkPropsV2): Link =>
  Link.static({
    nameLocaleKey: 'create',
    pathPart: 'create-subscription-template',
    parent: subscriptionTemplatesLink(organization),
    allowedPrivileges: [SUBSCRIPTIONCREATE],
  });

export const organizationOrdersLink = (parent: Link) =>
  Link.static({
    nameLocaleKey: 'organizationOrders',
    pathPart: 'organization-orders',
    parent,
    allowedPrivileges: [ORGANIZATIONREGISTRATIONREAD],
  });

export const organizationOrderDetailsLink = (parent: Link, key?: string) =>
  new Link({
    name: key || '',
    pathPart: key || ':organizationOrderId',
    parent,
    allowedPrivileges: [ORGANIZATIONREGISTRATIONREAD],
  });

export const createOrganizationFromOrder = (parent: ReturnType<typeof organizationOrderDetailsLink>) =>
  Link.static({
    nameLocaleKey: 'organizationCreate',
    pathPart: 'create-organization',
    parent,
    allowedPrivileges: [ORGANIZATIONCREATE],
  });

export const productsLink = (organization: DynamicLinkPropsV2): Link =>
  Link.static({
    nameLocaleKey: 'products',
    pathPart: 'products',
    parent: organizationLink(organization),
    allowedPrivileges: [PRODUCTREAD],
  });

export const chargingAuthResultsLink = (organization: DynamicLinkPropsV2): Link =>
  Link.static({
    nameLocaleKey: 'chargingAuthResults',
    pathPart: 'charging-auth-results',
    parent: organizationLink(organization),
    allowedPrivileges: [AUTHRESULTREAD],
  });

export const productLink = (organization: DynamicLinkPropsV2, { name, id } = dynamicLinkParams): Link =>
  new Link({
    name: name || '',
    pathPart: id,
    parent: productsLink(organization),
    allowedPrivileges: [PRODUCTREAD],
  });

export const productPricesLink = (organization: DynamicLinkPropsV2, { name, id } = dynamicLinkParams): Link =>
  Link.static({
    nameLocaleKey: 'productPrices',
    pathPart: 'price-profiles',
    parent: productLink(organization, { name, id }),
    allowedPrivileges: [PRODUCTREAD],
  });

export const productPricesAddLink = (organization: DynamicLinkPropsV2, { name, id } = dynamicLinkParams): Link =>
  Link.static({
    nameLocaleKey: 'addCountry',
    pathPart: 'add',
    parent: productPricesLink(organization, { name, id }),
    allowedPrivileges: [PRODUCTUPDATE],
  });

export const productTranslationsLink = (organization: DynamicLinkPropsV2, { name, id } = dynamicLinkParams): Link =>
  Link.static({
    nameLocaleKey: 'translations',
    pathPart: 'translations',
    parent: productLink(organization, { name, id }),
    allowedPrivileges: [PRODUCTREAD],
  });

export const productTranslationsAddLink = (organization: DynamicLinkPropsV2, { name, id } = dynamicLinkParams): Link =>
  Link.static({
    nameLocaleKey: 'addLanguage',
    pathPart: 'add',
    parent: productTranslationsLink(organization, { name, id }),
    allowedPrivileges: [PRODUCTUPDATE],
  });

export const createProductLink = (organization: DynamicLinkPropsV2): Link =>
  Link.static({
    nameLocaleKey: 'productCreate',
    pathPart: 'create-product',
    parent: productsLink(organization),
    allowedPrivileges: [PRODUCTCREATE],
  });

export const orgChargingKeysLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'chargingKeys',
    pathPart: 'charging-keys',
    allowedPrivileges: [CUSTOMERKEYREAD],
    parent,
  });
