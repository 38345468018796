import * as Sentry from '@sentry/react';
import { t } from 'i18n';
import { BaseError } from 'make-error';

interface ErrorDetail {
  message: string;
  messageKey: string;
  params: {
    [key: string]: number;
  };
}

export class CDMError extends BaseError {
  static async fromResponse(response: Response) {
    try {
      return CDMError.fromErrorObject(JSON.parse(await response.text()), response.status);
    } catch (e) {
      return new CDMError(t('somethingWentWrong'), [], response.status);
    }
  }

  static fromErrorObject(object: object, statusCode: number) {
    if (statusCode >= 500) {
      return new CDMError(t('somethingWentWrong'), [], statusCode);
    }

    try {
      const { details = [], message = '', messageKey, code, parameters, type, fieldViolations = [] } = object as any;
      // This is a fix that backend should be doing, it can mislead developers for translating new keys.
      const translationKey = messageKey || message.replace('.', '_');

      const error = new CDMError(
        type
          ? t(type, {
              ...(parameters || {}),
              defaultValue: message,
            })
          : [
              // There are still some error responses from the backend that contains the param 'details',
              // the code block below can be deleted when 'details' is removed from the backend.
              t(translationKey, {
                defaultValue: message,
              }),
              ...(typeof details[0] === 'object'
                ? details.map((detail: ErrorDetail) =>
                    t(detail.messageKey as any, { ...(detail.params || {}), defaultValue: detail.message }),
                  )
                : details),
            ].join(' '),
        details,
        statusCode,
        code,
        fieldViolations,
        type,
        parameters,
        message,
      );

      if (error.statusCode !== 404 && error.statusCode !== 401 && error.statusCode !== 403) {
        // This error is possibly caused by UI sent wrong parameters
        Sentry.captureException(error);
      }

      return error;
    } catch (error) {
      return new CDMError(JSON.stringify(object), [], statusCode);
    }
  }

  constructor(
    message: string,
    readonly details: string[],
    readonly statusCode: number,
    readonly code?: number,
    readonly fieldViolations?: Record<string, unknown>[],
    readonly type?: string,
    readonly parameters?: Record<string, unknown>,
    readonly errorMessage?: string,
  ) {
    super(message);
  }
}
